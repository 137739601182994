import React, { useState } from "react";
import { FaFacebook, FaWhatsapp, FaLinkedinIn } from "react-icons/fa";
import "./team.css";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import link from "./link.png";

const Team = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smooth scrolling
    });
  };
  const [showDropdown, setShowDropdown] = useState(Array(4).fill(false));

  const toggleDropdown = (index) => {
    setShowDropdown((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const teamMembers = [
    {
      img: "./Assets/hardik.jpg",
      name: "Hardik Lodha",
      position: "Co-Founder, CEO",
      details: "IIT Guwahati, Ex Byjus, Flipkart",
      linkedin: "https://www.linkedin.com/in/hardiklodha/",
      whatsapp:
        "https://wa.me/918608600419?text=Hi%20Team,%20Wanted%20to%20talk%20about%20something",
      facebook: "https://www.facebook.com/hardiklodha",
    },
    // {
    //   img: "./Assets/aanchal.jpg",
    //   name: "Aanchal Minda",
    // position: "Co-Founder, Chief CA",
    // details: "Ex Johnson and Johnson, L&T",
    //   linkedin: "https://www.linkedin.com/in/ca-aanchal-minda-595453149/",
    //   whatsapp: "https://wa.me/918608600419?text=Hi%20Team,%20Wanted%20to%20talk%20about%20something",
    //   facebook: "https://www.facebook.com/aanchalminda",
    // },
    {
      img: "./Assets/shubham.jpg",
      name: "Shubham Jain",
      position: "Co-Founder, COO",
      details: "IIT Roorkee, Ex Newton School",
      linkedin: "https://www.linkedin.com/in/shubhamjain1296/",
      whatsapp:
        "https://wa.me/918608600419?text=Hi%20Team,%20Wanted%20to%20talk%20about%20something",
      facebook: "https://www.facebook.com/shubhamjain",
    },
    {
      img: "./Assets/rounak.jpg",
      name: "Rounak Baral",
      position: "Co-Founder, CPO",
      details: "IIT Guwahati, Ex DateSetGo",
      linkedin: "https://www.linkedin.com/in/rounakbaral/",
      whatsapp:
        "https://wa.me/918608600419?text=Hi%20Team,%20Wanted%20to%20talk%20about%20something",
      facebook: "https://www.facebook.com/rounakbaral",
    },
  ];

  return (
    <div className="team_main">
      <div className="team">
        <Row className="why_choose_row">
          <Col sm={12}>
            <h2 className="why_choose_heading">Founding Team</h2>
          </Col>
        </Row>
        <Row>
          {teamMembers.map((member, index) => (
            <Col xs={12} md={3} key={index}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <div
                  className="member_link_image"
                  style={{ position: "relative" }}
                >
                  <Card.Img
                    variant="top"
                    src={member.img}
                    style={{ width: "100%" }}
                  />
                  <Card.Img
                    variant="bottom"
                    src={link}
                    onClick={() => toggleDropdown(index)}
                    style={{
                      width: "35px",
                      height: "35px",
                      position: "absolute",
                      top: "20px",
                      right: "20px",
                      background: "white",
                      padding: "6px",
                      borderRadius: "10%",
                      cursor: "pointer",
                    }}
                  />
                  {showDropdown[index] && (
                    <div
                      style={{
                        position: "absolute",
                        top: "54px",
                        right: "20px",
                        background: "white",
                        border: "0px solid #ccc",
                        borderRadius: "0% 0px 10% 10%",
                        padding: "0px",
                        zIndex: 1000,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <a
                        href={member.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLinkedinIn
                          className="icon"
                          size={24}
                          style={{ margin: "5px" }}
                        />
                      </a>
                      <a
                        href={member.whatsapp}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaWhatsapp
                          className="icon"
                          size={24}
                          style={{ margin: "5px" }}
                        />
                      </a>
                      <a
                        href={member.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebook
                          className="icon"
                          size={24}
                          style={{ margin: "5px" }}
                        />
                      </a>
                    </div>
                  )}
                </div>
                <Card.Body>
                  <Card.Title className="team_card_title">
                    {member.name}
                  </Card.Title>
                  <Card.Text className="team_card_text">
                    {member.position}
                    <br />
                    {member.details}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <div className="team_div">
          <Link to="/team-page" onClick={scrollToTop}>
            <button className="team_btn">Meet Our Team</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Team;
