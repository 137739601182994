import React from "react";
import { FaFacebook, FaWhatsapp, FaInstagram, FaLinkedin } from "react-icons/fa";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const OurTeam = () => {
  return (
    <>
      <div className="team_main">
        <div className="team">
          <Row>
            <Col sm={12}>
              <h2 className="why_choose_heading">Founders</h2>
              <p className="why_choose_para">
                Dedicated to transforming your wealth by leveraging technology and relevant industry experience
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/hardik.jpg" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Hardik Lodha</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/hardiklodha/">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Hardik%20Lodha">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Co-Founder, CEO<br></br> IIT Guwahati, Ex Byjus, Flipkart
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/aanchal.jpg" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Aanchal Minda</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/ca-aanchal-minda-595453149/">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Aanchal%20Minda">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Co-Founder, Chief CA<br></br> Ex Johnson and Johnson, L&T
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col> */}
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/shubham.jpg" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Shubham Jain</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/shubhamjain1296/">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Shubham%20Jain">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Co-Founder, COO<br></br> IIT Roorkee, Ex Newton School
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/rounak.jpg" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Rounak Baral</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/rounakbaral/">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Rounak%20Baral">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Co-Founder, CPO<br></br> IIT Guwahati, Ex DateSetGo
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <h2 className="why_choose_heading" style={{ marginTop: '50px' }}>Wealth Partners</h2>
              <p className="why_choose_para">
                Dedicated to transforming your wealth by leveraging technology and relevant industry experience
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/nihar.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Nihar Setia</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/caniharsetia/">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Nihar%20Setia">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Taxes and Investment<br></br> Ex Abvise
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/raj.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Raj Motani</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/raj-motani-8b13401a1/">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Raj%20Motani">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Taxes and Investment<br></br> Ex Eisner Amper
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/samta.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Samta Saruparia</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/samta-saruparia-3371ba4a/">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Samta%20Saruparia">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Taxes and Investment<br></br> Ex Anil Shah & Associates
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/anuj.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Anuj Mohindra</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/ca-anuj-mohindra-656756181/">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Anuj%20Mohindra">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Taxes and Investment<br></br>Ex Barclays
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: '40px' }}>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/aniksha.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Aniksha Bafna</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/anikasha-bafna-8010a5290/?originalSubdomain=in">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Aniksha%20Bafna">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Taxes and Investment<br></br>Ex GAURAV CHOPRA & ASSOCIATES
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/aman.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Aman Nahar</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/aman-nahar/">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Aman%20Nahar">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Taxes and Investment<br></br>Ex GNB & Co
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/shainit.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Shainit Dhakad</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/shainit-dhakad">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Shainit%20Dhakad">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Taxes and Investment<br></br>Ex S-D-A-N & Associates
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/ashutosh.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Ashutosh Joshi</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Ashutosh%20Joshi">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Taxes and Investment<br></br>Ex P.G. Joshi and Co
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <h2 className="why_choose_heading" style={{ marginTop: '50px' }}>Leadership Team</h2>
              <p className="why_choose_para">
                Dedicated to transforming your wealth by leveraging technology and relevant industry experience
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/ishita.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Ishita Jain</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/ishita-jain-9a8a9b171">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Ishita%20Jain">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    HR Head
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/tushar.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Tushar Tomar</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/hustlepreneur">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Tushar%20Tomar">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Operations
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/aljit.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Aljit Paul</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/aljith-madambi-chonedan-6a05201b3/">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Aljit%20Paul">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Technology
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/vivek.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Vivek Chimnani</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/vivek-c-8660a3270/">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Vivek%20Chimnani">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Technology
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: '40px' }}>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/javaz.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Abdul Javaz</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Abdul%20Javaz">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Technology
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/vandit.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Vandit Dubey</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/vanditdubey/">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Vandit%20Dubey">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Product
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/jaspinder.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Jaspinder Singh</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/jaspinder-singh-6a332a165">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Jaspinder%20Singh">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Founder's Office
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/ketki.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Ketki Padwad</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Ketki%20Padwad">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Business
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: '40px' }}>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/harish-h.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Harish H</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Harish%20H">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Business
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/amina.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Amina Anjum</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Amina%20Anjum">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Business
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/arjun.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Arjun Au</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/arjun-au-6873301aa/">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Arjun%20Au">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Business
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/harish-rs.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Harish R S</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/harish-r-s-2128982a7">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Harish%20R%20S">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Business
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: '40px' }}>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/abhinav.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Abhinav Singh</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/abhinavprsingh">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Abhinav%20Singh">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Business
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/neha.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Neha Palegar</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com/in/neha-palegar-88008829a/">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Neha%20Palegar">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Business
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/palash.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Palash Saxena</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Palash%20Saxena">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Business
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="team_custom-card" style={{ width: "100%" }}>
                <Card.Img variant="top" src="./Assets/tusharkumar.png" />
                <Card.Body>
                  <div className="card_title_with_icons">
                    <Card.Title className="team_card_title">Tushar Kumar</Card.Title>
                    <div className="social_icons">
                      <a href="https://www.linkedin.com">
                        <FaLinkedin className="icon" size={24} />
                      </a>
                      <a href="https://wa.me/918608600419?text=Hey%20I%20want%20to%20talk%20to%20Tushar%20Kumar">
                        <FaWhatsapp className="icon" size={24} />
                      </a>
                    </div>
                  </div>
                  <Card.Text className="team_card_text">
                    Social Media
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default OurTeam;
